<template>
  <div>
   <h1>Escritorio</h1>
  </div>
</template>

<script>

export default {
  name: 'Dashboard'
}
</script>
